import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_app_bar_title = _resolveComponent("v-app-bar-title")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_ctx.environment !== 'production')
    ? (_openBlock(), _createBlock(_component_v_app_bar, {
        key: 0,
        height: "20",
        color: "#e3a82b",
        class: "environment-banner"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_app_bar_title, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.bannerText), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
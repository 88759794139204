import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_formio = _resolveComponent("formio")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_footer_buttons = _resolveComponent("footer-buttons")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_formio, {
      formName: _ctx.selectedForm,
      data: _ctx.initialData,
      readOnly: _ctx.isReadOnly,
      onLoaded: _ctx.formLoaded,
      onChanged: _ctx.formChanged,
      onSubmitted: _ctx.submitted,
      onRender: _ctx.formRender,
      onCustomEvent: _ctx.customEvent
    }, null, 8, ["formName", "data", "readOnly", "onLoaded", "onChanged", "onSubmitted", "onRender", "onCustomEvent"]),
    _createVNode(_component_footer_buttons, {
      justify: "space-between",
      processing: _ctx.processing,
      onPrimaryClick: _ctx.wizardGoNext,
      onSecondaryClick: _ctx.wizardGoPrevious,
      showPrimaryButton: !_ctx.isLastPage,
      showSecondaryButton: !_ctx.isFirstPage,
      primaryLabel: !_ctx.isFirstPage ? 'Next section' : 'Start your application',
      secondaryLabel: "Previous section",
      class: "mx-0"
    }, _createSlots({ _: 2 }, [
      (!_ctx.isReadOnly && _ctx.isLastPage)
        ? {
            name: "primary-buttons",
            fn: _withCtx(() => [
              _createElementVNode("span", null, [
                (_ctx.isSaveDraftAllowed)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 0,
                      color: "primary",
                      variant: "outlined",
                      loading: _ctx.savingDraft,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('saveDraft')))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.savingDraft ? "Saving..." : "Save draft"), 1)
                      ]),
                      _: 1
                    }, 8, ["loading"]))
                  : _createCommentVNode("", true),
                (!_ctx.isFirstPage)
                  ? (_openBlock(), _createBlock(_component_v_btn, {
                      key: 1,
                      class: "ml-2",
                      disabled: _ctx.processing,
                      color: "primary",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('wizardSubmit'))),
                      loading: _ctx.processing
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.processing ? "Submitting..." : "Submit application"), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "loading"]))
                  : _createCommentVNode("", true)
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032, ["processing", "onPrimaryClick", "onSecondaryClick", "showPrimaryButton", "showSecondaryButton", "primaryLabel"])
  ], 64))
}
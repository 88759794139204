import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_environment_banner = _resolveComponent("environment-banner")!
  const _component_snack_bar = _resolveComponent("snack-bar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_environment_banner),
      _createVNode(_component_snack_bar, { snackBarOptions: _ctx.snackBarOptions }, null, 8, ["snackBarOptions"]),
      _createVNode(_component_router_view)
    ]),
    _: 1
  }))
}
/**
 * Student Route Names
 */
export const StudentRoutesConst = {
  APP_STUDENT: Symbol(),
  STUDENT_DASHBOARD: Symbol(),
  LOGIN: Symbol(),
  STUDENT_PROFILE_CREATE: Symbol(),
  STUDENT_PROFILE_EDIT: Symbol(),
  FINANCIAL_AID_APPLICATION: Symbol(),
  APPLICATIONS: Symbol(),
  NOTICE_OF_ASSESSMENT_VIEW: Symbol(),
  ASSESSMENT_AWARD_VIEW: Symbol(),
  PERSONAL_INFO: Symbol(),
  SELECT_PROGRAM: Symbol(),
  FINANCIAL_INFO: Symbol(),
  CONFIRM_SUBMISSION: Symbol(),
  DYNAMIC_FINANCIAL_APP_FORM: Symbol(),
  STUDENT_APPLICATION_FORM: Symbol(),
  DYNAMIC_FINANCIAL_APP_FORM_VIEW: Symbol(),
  NOTIFICATIONS: Symbol(),
  STUDENT_FILE_UPLOADER: Symbol(),
  NOTIFICATIONS_SETTINGS: Symbol(),
  STUDENT_APPLICATION_SUMMARY: Symbol(),
  STUDENT_APPLICATION_DETAILS: Symbol(),
  STUDENT_REQUEST_CHANGE: Symbol(),
  STUDENT_ACCOUNT_ACTIVITY: Symbol(),
  STUDENT_OVERAWARDS: Symbol(),
  STUDENT_ACCOUNT_APPLICATION_IN_PROGRESS: Symbol(),
  STUDENT_APPEAL_REQUEST: Symbol(),
  STUDENT_APPLICATION_OFFERING_CHANGE_REQUEST: Symbol(),
  STUDENT_REQUESTED_APPLICATION_OFFERING_CHANGE: Symbol(),
  STUDENT_ACTIVE_APPLICATION_DETAILS: Symbol(),
  INVALID_BETA_USER: Symbol(),
};

/**
 * Institution Routes Names
 */
export const InstitutionRoutesConst = {
  APP_INSTITUTION: Symbol(),
  INSTITUTION_DASHBOARD: Symbol(),
  LOGIN: Symbol(),
  LOGIN_WITH_BUSINESS_BCEID: Symbol(),
  INSTITUTION_PROFILE: Symbol(),
  INSTITUTION_CREATE: Symbol(),
  INSTITUTION_PROFILE_EDIT: Symbol(),
  INSTITUTION_USER_PROFILE: Symbol(),
  MANAGE_LOCATIONS: Symbol(),
  ADD_INSTITUTION_LOCATION: Symbol(),
  EDIT_INSTITUTION_LOCATION: Symbol(),
  MANAGE_DESIGNATION: Symbol(),
  DESIGNATION_REQUEST: Symbol(),
  DESIGNATION_VIEW: Symbol(),
  MANAGE_USERS: Symbol(),
  VIEW_LOCATION_PROGRAMS: Symbol(),
  EDIT_LOCATION_PROGRAMS: Symbol(),
  ADD_LOCATION_PROGRAMS: Symbol(),
  LOCATION_PROGRAMS: Symbol(),
  ADD_LOCATION_OFFERINGS: Symbol(),
  EDIT_LOCATION_OFFERINGS: Symbol(),
  VIEW_LOCATION_OFFERINGS: Symbol(),
  PROGRAM_INFO_REQUEST_SUMMARY: Symbol(),
  COE_SUMMARY: Symbol(),
  COE_EDIT: Symbol(),
  ACTIVE_APPLICATIONS_SUMMARY: Symbol(),
  ACTIVE_APPLICATION_EDIT: Symbol(),
  PROGRAM_INFO_REQUEST_EDIT: Symbol(),
  LOCATION_STUDENTS: Symbol(),
  LOCATION_USERS: Symbol(),
  DISABLED_LOGIN: Symbol(),
  UNKNOWN_LOGIN: Symbol(),
  SCHOLASTIC_STANDING_VIEW: Symbol(),
  OFFERING_REQUEST_CHANGE: Symbol(),
  OFFERINGS_UPLOAD: Symbol(),
  WITHDRAWAL_UPLOAD: Symbol(),
  REPORTS: Symbol(),
  INSTITUTION_STUDENT_SEARCH: Symbol(),
  STUDENT_PROFILE: Symbol(),
  STUDENT_DETAILS: Symbol(),
  STUDENT_APPLICATIONS: Symbol(),
  STUDENT_APPLICATION_DETAILS: Symbol(),
  STUDENT_RESTRICTIONS: Symbol(),
  STUDENT_FILE_UPLOADS: Symbol(),
  STUDENT_BALANCES: Symbol(),
  STUDENT_NOTES: Symbol(),
  ASSESSMENTS_SUMMARY: Symbol(),
  APPLICATION_EXCEPTION: Symbol(),
  STUDENT_APPEAL_REQUEST: Symbol(),
  ASSESSMENT_AWARD_VIEW: Symbol(),
  NOTICE_OF_ASSESSMENT_VIEW: Symbol(),
  REQUEST_CHANGE: Symbol(),
  REQUEST_CHANGE_AVAILABLE_TO_CHANGE: Symbol(),
  REQUEST_CHANGE_IN_PROGRESS: Symbol(),
  REQUEST_CHANGE_COMPLETED: Symbol(),
  REQUEST_CHANGE_FORM_SUBMIT: Symbol(),
  REQUEST_CHANGE_FORM_VIEW: Symbol(),
};

/**
 * AEST Routes Names
 */
export const AESTRoutesConst = {
  APP_AEST: Symbol(),
  AEST_DASHBOARD: Symbol(),
  SEARCH_STUDENTS: Symbol(),
  STUDENT_DETAILS: Symbol(),
  STUDENT_PROFILE: Symbol(),
  STUDENT_APPLICATIONS: Symbol(),
  STUDENT_RESTRICTION: Symbol(),
  STUDENT_FILE_UPLOADS: Symbol(),
  STUDENT_NOTES: Symbol(),
  SIN_MANAGEMENT: Symbol(),
  CAS_SUPPLIER_MANAGEMENT: Symbol(),
  STUDENT_BALANCES: Symbol(),
  PROGRAM_DETAILS: Symbol(),
  SEARCH_INSTITUTIONS: Symbol(),
  INSTITUTION_PROFILE: Symbol(),
  INSTITUTION_PROFILE_EDIT: Symbol(),
  INSTITUTION_PROFILE_CREATE: Symbol(),
  INSTITUTION_PROGRAMS: Symbol(),
  INSTITUTION_LOCATIONS: Symbol(),
  EDIT_INSTITUTION_LOCATION: Symbol(),
  INSTITUTION_USERS: Symbol(),
  INSTITUTION_DESIGNATION: Symbol(),
  INSTITUTION_RESTRICTIONS: Symbol(),
  INSTITUTION_NOTES: Symbol(),
  INSTITUTION_DETAILS: Symbol(),
  APPLICATION_DETAILS: Symbol(),
  ASSESSMENTS_SUMMARY: Symbol(),
  SCHOLASTIC_STANDING_VIEW: Symbol(),
  SUPPORTING_USER_DETAILS: Symbol(),
  LOGIN: Symbol(),
  LOGIN_WITH_NOT_ALLOWED_USER: Symbol(),
  VIEW_PROGRAM: Symbol(),
  VIEW_OFFERING: Symbol(),
  PENDING_DESIGNATIONS: Symbol(),
  DESIGNATION_VIEW: Symbol(),
  STUDENT_APPEAL_REQUESTS_APPROVAL: Symbol(),
  STUDENT_APPLICATION_OFFERING_CHANGE_REQUEST: Symbol(),
  NOTICE_OF_ASSESSMENT_VIEW: Symbol(),
  REPORTS: Symbol(),
  APPLICATION_EXCEPTIONS_APPROVAL: Symbol(),
  APPLICATION_EXCEPTIONS_PENDING: Symbol(),
  OFFERING_CHANGE_REQUESTS: Symbol(),
  OFFERING_CHANGE_REQUEST_VIEW: Symbol(),
  APPLICATION_APPEALS_PENDING: Symbol(),
  STUDENT_ACCOUNT_APPLICATIONS: Symbol(),
  STUDENT_ACCOUNT_APPLICATIONS_APPROVAL: Symbol(),
  ASSESSMENT_AWARD_VIEW: Symbol(),
  REQUEST_CHANGE_OFFERINGS: Symbol(),
  APPLICATION_RESTRICTIONS_MANAGEMENT: Symbol(),
  APPLICATION_STATUS_TRACKER: Symbol(),
  APPLICATION_VERSION_DETAILS: Symbol(),
};

/**
 * Supporting Users Routes Names
 */
export const SupportingUserRoutesConst = {
  APP_SUPPORTING_USERS: Symbol(),
  DASHBOARD: Symbol(),
  LOGIN: Symbol(),
  PARENT_INFORMATION: Symbol(),
  PARTNER_INFORMATION: Symbol(),
};

/**
 * Shared Routes Names
 */
export const SharedRouteConst = {
  PAGE_NOT_FOUND: Symbol(),
  FORBIDDEN_USER: Symbol(),
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_student_appeal_requests_approval = _resolveComponent("student-appeal-requests-approval", true)!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, null, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Assessment",
        subTitle: "View request(s)",
        routeLocation: _ctx.assessmentsSummaryRoute
      }, null, 8, ["routeLocation"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_student_appeal_requests_approval, {
        appealId: _ctx.appealId,
        backRouteLocation: _ctx.assessmentsSummaryRoute,
        showApprovalDetails: true,
        onSubmitted: _ctx.submitted
      }, null, 8, ["appealId", "backRouteLocation", "onSubmitted"])
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    app: "",
    class: "body-background",
    permanent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_list_item, {
        density: "compact",
        nav: "",
        "prepend-icon": _ctx.studentMenu.studentApplication.props?.prependIcon,
        title: _ctx.studentMenu.studentApplication.title,
        onClick: _ctx.studentMenu.studentApplication.command
      }, null, 8, ["prepend-icon", "title", "onClick"]),
      (_ctx.relatedParentPartners.length)
        ? (_openBlock(), _createBlock(_component_v_list, {
            key: 0,
            density: "compact",
            nav: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_subheader, null, {
                default: _withCtx(() => [
                  _createTextVNode("Supporting users")
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.relatedParentPartners, (relatedParentPartner) => {
                return (_openBlock(), _createBlock(_component_v_list_item, {
                  key: relatedParentPartner.title,
                  "prepend-icon": relatedParentPartner.props?.prependIcon,
                  title: relatedParentPartner.title,
                  onClick: relatedParentPartner.command
                }, null, 8, ["prepend-icon", "title", "onClick"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_v_list_item, {
        density: "compact",
        nav: "",
        "prepend-icon": _ctx.studentMenu.assessments.props?.prependIcon,
        title: _ctx.studentMenu.assessments.title,
        onClick: _ctx.studentMenu.assessments.command
      }, null, 8, ["prepend-icon", "title", "onClick"]),
      _createVNode(_component_v_list_item, {
        density: "compact",
        nav: "",
        "prepend-icon": 
        _ctx.studentMenu.applicationRestrictionsManagement.props?.prependIcon
      ,
        title: _ctx.studentMenu.applicationRestrictionsManagement.title,
        onClick: _ctx.studentMenu.applicationRestrictionsManagement.command
      }, null, 8, ["prepend-icon", "title", "onClick"]),
      _createVNode(_component_v_list_item, {
        density: "compact",
        nav: "",
        "prepend-icon": _ctx.studentMenu.applicationStatus.props?.prependIcon,
        title: _ctx.studentMenu.applicationStatus.title,
        onClick: _ctx.studentMenu.applicationStatus.command
      }, null, 8, ["prepend-icon", "title", "onClick"]),
      (_ctx.applicationHistory.length)
        ? (_openBlock(), _createBlock(_component_v_list, {
            key: 1,
            density: "compact",
            nav: ""
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.applicationHistory, (application) => {
                return (_openBlock(), _createBlock(_component_v_list_group, {
                  key: application.title,
                  title: application.title,
                  value: application.title
                }, {
                  activator: _withCtx(({ props }) => [
                    _createVNode(_component_v_list_item, _mergeProps(props, {
                      title: application.title
                    }), null, 16, ["title"])
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(application.children, (child) => {
                      return (_openBlock(), _createBlock(_component_v_list_item, {
                        key: child.title,
                        title: child.title,
                        "prepend-icon": child.props?.prependIcon,
                        to: child.props?.to
                      }, null, 8, ["title", "prepend-icon", "to"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["title", "value"]))
              }), 128))
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_navigator = _resolveComponent("header-navigator")!
  const _component_body_header = _resolveComponent("body-header")!
  const _component_Column = _resolveComponent("Column")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_toggle_content = _resolveComponent("toggle-content")!
  const _component_content_group = _resolveComponent("content-group")!
  const _component_full_page_container = _resolveComponent("full-page-container")!

  return (_openBlock(), _createBlock(_component_full_page_container, { "full-width": true }, {
    header: _withCtx(() => [
      _createVNode(_component_header_navigator, {
        title: "Student requests",
        subTitle: "Accounts"
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_body_header, {
        title: "Pending account requests",
        subTitle: "Basic BCeID account requests that require ministry review.",
        recordsCount: _ctx.accountApplications?.length
      }, null, 8, ["recordsCount"]),
      _createVNode(_component_content_group, null, {
        default: _withCtx(() => [
          _createVNode(_component_toggle_content, {
            toggled: !_ctx.accountApplications.length
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.accountApplications,
                paginator: true,
                rows: _ctx.DEFAULT_PAGE_LIMIT,
                rowsPerPageOptions: _ctx.PAGINATION_LIST,
                totalRecords: _ctx.accountApplications.length
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    header: "Date submitted",
                    headerClass: "text-no-wrap"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.submittedDate)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, {
                    header: "Given names",
                    field: "givenNames"
                  }),
                  _createVNode(_component_Column, {
                    header: "Last name",
                    field: "lastName"
                  }),
                  _createVNode(_component_Column, {
                    header: "Date of birth",
                    headerClass: "text-no-wrap"
                  }, {
                    body: _withCtx((slotProps) => [
                      _createElementVNode("span", null, _toDisplayString(_ctx.dateOnlyLongString(slotProps.data.dateOfBirth)), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Column, { header: "Action" }, {
                    body: _withCtx((slotProps) => [
                      _createVNode(_component_v_btn, {
                        color: "primary",
                        onClick: ($event: any) => (_ctx.goToStudentAccountApplication(slotProps.data.id)),
                        "data-cy": "viewStudentAccountApplication"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" View ")
                        ]),
                        _: 2
                      }, 1032, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value", "rows", "rowsPerPageOptions", "totalRecords"])
            ]),
            _: 1
          }, 8, ["toggled"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "category-header-large primary-color mt-3"
}
const _hoisted_2 = {
  key: 0,
  class: "pb-2"
}
const _hoisted_3 = { class: "mx-4 mb-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider_inset_opaque = _resolveComponent("v-divider-inset-opaque")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: _ctx.showHideDialog,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showHideDialog) = $event)),
    width: _ctx.width,
    persistent: "",
    "no-click-animation": true,
    scrollable: "",
    fullscreen: _ctx.showFullScreen
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, {
        elevation: "10",
        "max-width": _ctx.maxWidth,
        class: "modal-height"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header", {}, () => [
                (_ctx.title)
                  ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.title), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 3
          }),
          _createVNode(_component_v_divider_inset_opaque, { class: "my-1" }),
          _createVNode(_component_v_card_text, { class: "pt-0" }, {
            default: _withCtx(() => [
              (_ctx.subTitle)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.subTitle), 1))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "content", {}, () => [
                _createTextVNode("Please add the modal content here!")
              ])
            ]),
            _: 3
          }),
          _createVNode(_component_v_divider_inset_opaque),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "footer")
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      }, 8, ["max-width"])
    ]),
    _: 3
  }, 8, ["modelValue", "width", "fullscreen"]))
}